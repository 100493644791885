.aside-menu.filters {

  border : none;

  .aside-content {
    padding : 18px;
  }
  .filter-title {
    color : #fff;
    display: flex;
    width: 100%;
    background: $primary;
    flex-direction: row;
    height: 55px;
    align-items: center;
    border:none;

    i.fa-filter {
      margin : 0 16px 0 18px;
    }

    i.fa-remove {
      margin : 0 24px 0 0;
    }

    span {
      flex : 1;
      text-align: center;
    }

    &:hover {
      text-decoration: none;
      color : #fff;
    }

  }

  .date {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 25px;

    .form-group {
      flex : 1;
      margin : 0;

      .form-control-label {
        text-transform: none;
        font-weight: 300;
        text-align: center;
        display: block;
      }

      .form-control {
        width: 100px;
        padding: 0;
        text-align: center;
      }

    }


  }

  button[type=submit].btn {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }


}

.btn-filter {


}




@include media-breakpoint-down(lg) {

  .main {
    margin-right: 0 !important;
  }
  .aside-menu.filters {

    display: block;
    box-shadow: none;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 66px;
    bottom: 0;

  }

  .aside-menu-hidden {

    .aside-menu.filters {
      display: none;
    }

  }

};
