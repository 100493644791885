// Here you can add other styles
.navbar {
	.form-inline {
		i {
			color: $navbar-color;
		}
		.form-control {
			min-width: 250px;
			color: $navbar-color;
			border: 0;

			&::placeholder {
				color: $navbar-color;
			}
		}
		.form-control::-webkit-input-placeholder {
			color: $navbar-color;
		}
		.form-control:-moz-placeholder {
			color: $navbar-color;
		}
		.form-control::-moz-placeholder {
			color: $navbar-color;
		}
		.form-control:-ms-input-placeholder {
			color: $navbar-color;
		}
	}
}

.sidebar {
	background-color: white;
	color: #F86B27;
	.sidebar-header {
		background-color: #F86B27;
		padding: 20px;
		.img-avatar {
			width: 80px;
			margin: 20px auto 10px;
			border-radius: 50em;
		}

		> .btn-group {
			margin-top: 10px;
		}

		.btn-link {
			color: $text-muted;

			&:hover {
				color: $sidebar-color;
				text-decoration: none;
			}
		}
		a {
			color: white;
		}

		.nav-link {
			a {
				color: #F86B27;
			}
		}
	}
}

.app-header.navbar .navbar-brand {
	background-size: 180px auto;
}

.card-header .card-actions a,
.card-header .card-actions button {
	display: block;
	float: left;
	width: auto;
	min-width: 50px;
	padding: 0.75rem 0.75rem;
	margin: 0 !important;
	text-align: center;
	border: 0;
	border-left: 1px solid #d1d4d7;
	box-shadow: 0;
}

.breadcrumb {
	background: #fff;
	border-bottom: 1px solid #e3e8ec;
	margin-bottom: 1.5rem;
}

.jumbotron {
	padding: 0;
}

.list-group-item {
	text-align: left;
}

.field-info {
	display: flex;
	flex-direction: row;
	padding: 5px 0;

	.label {
		width: 200px;
	}

	.value {
		flex: 1;
	}
}

.form-horizontal.form-quote {
	.form-group {
		input[type="number"] {
			max-width: 200px;
		}
	}

	.card-title {
		margin: 1rem 0;
		border-bottom: solid 1px #d1d4d7;
		padding: 1rem 0;
	}
}

//A sortir dans une feuille dédié à la commnande
.detail-financement {
	font-size: 14px;

	.row {
		padding: 10px 0;
	}

	.col-md-4 {
		text-align: right;
	}
}

.form-vertical {
	.form-group {
		flex-direction: column;

		.col-md-3,
		.col-md-9 {
			flex: 1;
			max-width: 100%;
		}
	}
}

.DateInput_input {
	padding: 7px 12px 7px !important;
}

.react-bootstrap-daterangepicker-container {
	display: block !important;
}

.select2Search {
	.Select-control {
		border-radius: 0;
		height: 44px;

		.Select-multi-value-wrapper {
			font-size: 20px;
		}
	}
}

.aside-menu {
	.list-group {
		.list-group-item {
			border: none;
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			margin-bottom: 0;
		}
	}
}

@media (min-width: 992px) {
	.modal-lg {
		max-width: 90%;
	}
}

/*
 * Glyphicons
 *
 * Special styles for displaying the icons and their classes in the docs.
 */

.bs-glyphicons {
	overflow: hidden;
}
.bs-glyphicons-list {
	padding-left: 0;
	list-style: none;
}
.bs-glyphicons li {
	float: left;
	width: 50%;
	height: 115px;
	padding: 10px;
	font-size: 10px;
	line-height: 1.4;
	text-align: center;
	background-color: #f9f9f9;
	border: 1px solid #fff;
}
.bs-glyphicons .glyphicon {
	margin-top: 5px;
	margin-bottom: 10px;
	font-size: 24px;
}
.bs-glyphicons .glyphicon-class {
	display: block;
	text-align: center;
	word-wrap: break-word; /* Help out IE10+ with class names */
}
.bs-glyphicons li:hover {
	color: #fff;
	background-color: #563d7c;
}

@media (min-width: 768px) {
	.bs-glyphicons {
		margin-right: 0;
		margin-left: 0;
	}
	.bs-glyphicons li {
		width: 25%;
		font-size: 12px;
	}
}

.react-toggle {
	touch-action: pan-x;
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4d4d4d;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #000000;
}

.react-toggle--checked {
	.react-toggle-track {
		background-color: #19ab27;
	}
	&:hover:not(.react-toggle--disabled) .react-toggle-track {
		background-color: #128d15;
	}
}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4d4d4d;
	border-radius: 50%;
	background-color: #fafafa;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 3px 2px #0099e0;
	-moz-box-shadow: 0px 0px 3px 2px #0099e0;
	box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 5px 5px #0099e0;
	-moz-box-shadow: 0px 0px 5px 5px #0099e0;
	box-shadow: 0px 0px 5px 5px #0099e0;
}

input[type="color"] {
	height: 50px;
}

.markerFloor {
	transform: translate(-50%, -50%);
}


.login-background {
	background-image: url(/img/me-connecter.jpg);
	background-size: cover;

	background-position: center center;
}