.dropzone {
	display: flex;
	height: 150px;
	border: dashed 1px #ccc;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
	cursor: pointer;
	flex-direction: column;

	.file {
		font-size: 18px;
	}
}
