.dashboard {


  .app-body {
    .main {margin-top:0;}
  }


  .rc-switch {
    margin: 10px;
    align-self: flex-end;
    width: 102px;
    height: 30px;

  }

  .pagination-bottom {
    display: none;
  }
  .rt-thead {
    display: none;
  }

  .goals {

    display: flex;
    flex-direction: row;
    height: 125px;
    border-top : solid 1px $bgColor;

    &:first-child {border-top : none;}

    .goal {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left : solid 1px $bgColor;
      flex:1;
      &:first-child {border-left : none;}


    }
    .label {
      text-transform: uppercase;
    }

    .nbr {
      font-size: 18px;
      font-weight: 700;
      color:$primary;
    }

    .chart {
      width: 60px; height : 60px;
      border-radius: 40px;
      border:solid 1px #d0d0d0;
      margin-bottom: 4px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &.cancel {
      .label {
        color : lighten($red, 10%);
      }

      .nbr {
        font-size: 24px;
        color : $red;

        span {
          font-weight: 100;
        }
      }
    }

    &.ca {
      height: auto;
      padding : 9px 20px;
      display: block;
      border-top: none;

      i {
        display: block;
        color:$primary;
      }

      .goal-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .progress {
        height:4px;
        border-radius: 4px;
        >div {
          background-color: $primary;
          height: 4px;
          border-radius: 4px;
        }
      }

    }

  }

}

