.image-modal {
    width: 100%;
}

.modal-content.floor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px;

    .column {
        flex: 0 0 20%;
        padding-right: 10px;

        .lot-list {
            list-style: none;
            padding: 0;

            li {
                a {
                    display: block;
                    padding: 10px;
                    border-bottom: 1px solid #EEE9EB;
                    color: #000000;

                    &.active {
                        background: lightcoral
                    }

                    &:hover {
                        background: lightblue;
                    }
                }
            }
        }
    }

    .content {
        flex: 0 0 80%;

        .image-modal {
            width: 100%;
        }
    }
}

.markerFloor {
    position: absolute;
    height: 20px;
    width: 20px;
    background: grey;
    border-radius: 20px;

    &.active {
        background: pink
    }
}

#image-container {
    position: relative;
}

