.tplt {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	&.tplt-select {
		button {
			display: none;
		}
	}
}
.tpl-bloc {
	display: flex;
	border: solid 3px #f5f5f5;
	margin: 5px;
	padding: 5px;
	flex-direction: column;

	&.active {
		border: solid 3px #a2dcfb;
	}

	.tpl-background {
		background: #a2dcfb;
		background-size: cover;
		border-radius: 3px;
		flex: 1;
		display: flex;
		.tpl-txt {
			background: rgba(255, 255, 255, 0.8);
			margin: 0;
		}
	}
	.tpl-media {
		flex: 1;
		background: #a2dcfb;
		border-radius: 3px;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border: dashed 3px transparent;
		margin: 10px;
		&:hover {
			border: dashed 3px #6fb5e4;
		}

		img {
			max-width: 100%;
		}

		i {
			align-items: center;
			justify-content: center;
			color: #6fb5e4;
			font-size: 30px;
		}
	}
	&.tpl-row {
		flex-direction: row;
	}
	&.tpl-col {
		flex-direction: column;
		.tpl-media {
			width: 50%;
			margin: auto;
		}
	}
	.tpl-title {
		display: flex;
		flex: 1;
		flex-direction: column;
		cursor: pointer;
		> div {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			.row-title {
				background: #6fb5e4;
				height: 10px;
				width: 50%;
				margin: 2px 0;
			}
		}
	}
	.tpl-txt {
		display: flex;
		flex: 1;
		flex-direction: column;
		cursor: pointer;
		border: dashed 3px transparent;
		margin: 10px;
		&:hover {
			border: dashed 3px #6fb5e4;
		}
	}
	.mb-5,
	.mt-5,
	.offset-md-1,
	.order-md-2 {
		margin: 0 !important;
		flex: none;
		max-width: 100%;
	}
	.bold {
		font-weight: 700;
	}
}

.tplt-container {
	.tplt-slider-horizontal {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.card {
			width: 32%;
			margin-right: 10px;
			.card-body {
				padding: 0;
			}
		}
	}
}
.tplt-content {
	display: flex;
	flex: 1;
	flex-direction: column;
	> div {
		flex: 1;
	}
	.tpl-bloc {
		.tpl-background {
			min-height: 200px;
		}
	}
}

.content {
	.card {
		border: solid 1px blue;
		.card {
			border: solid 1px green;

			.btn-primary {
				background: green;
			}
			.card {
				border: solid 1px orange;
				.btn-primary {
					background: orange;
				}
			}
		}
	}
}
.tplt-list {
	display: flex;
	flex-direction: column;
	a {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		color: #000;
		font-size: 16px;
		height: 50px;
		border: solid 1px transparent;
		&:hover {
			text-decoration: none;
			border: solid 1px #6fb5e4;
		}
		&.active {
			text-decoration: none;
			border: solid 1px #a2dcfb;
		}

		> div {
			padding: 5px;
		}
		img {
			margin: 5px;
		}
	}
}
