.locals {
	flex-direction: row;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 10px;
}
.locals a {
	display: block;
	margin: 0 0 0 5px;
	border: solid 2px transparent;
}

.locals a.active {
	border: solid 2px #006fc5;
}

.locals a:hover {
	border: solid 2px #006fc5;
	cursor: pointer;
}
.modal-header .locals a {
	margin: 0 5px 0 0;
}
