//Fonts
@import url('https://fonts.googleapis.com/css?family=Barlow');
@import url('https://fonts.googleapis.com/css?family=Barlow|Pacifico');

$font1:                             'Barlow', sans-serif;
$font2:                             'Pacifico', cursive;

//Colors
$primary: #F86B27;
$secondary: rgba(204, 212, 30, 0.5);
$success: #28a745;
$bgColor:                           #ededed;

$textNormal: #575757;


$navbar-icon-toggler-color:         #ffffff;

$theme-colors: (
  "primary":    $primary,
);